import * as React from "react"
import * as style from './layout.module.css'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div>Copyright © {new Date().getFullYear()} Janky Reviews
      </div>
      <div><Link to="/disclosure-policy" className={style.navLinkText}>Disclosure & Privacy Policy</Link>
      </div>
    </footer>
  )
}

export default Footer
