import * as React from "react"
import * as style from './layout.module.css'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <div>
          <nav>
                <ul className={style.navLinks}>
                    <li className={style.navLinkItem}>
                        <Link to="/" className={style.navLinkText}>
                            Home
                        </Link>
                    </li>
                    <li className={style.navLinkItem}>
                        <Link to="/about" className={style.navLinkText}> 
                            About
                        </Link>
                    </li>         
                    <li className={style.navLinkItem}>
                        <Link to="/contact" className={style.navLinkText}> 
                            Contact
                        </Link>
                    </li>                                                      
                </ul>
            </nav>
            <header className={style.siteTitle}>Janky Reviews</header> 
            </div>
  )
}

export default Header