import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as style from './layout.module.css'
import Footer from './footer'
import Header from './header'
import Sticky from './nav-sticky'

const Layout2 = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)    
    return (
        <div className={style.container}>
            <Header />
            <Sticky />        
            <main className={style.main}>
                <h1 className={style.heading}>{pageTitle}</h1>
                {children}
            </main>
            <Footer />
        </div>
    )
}


export default Layout2