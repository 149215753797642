import * as React from "react"
import * as style from './layout.module.css'
import { Link } from 'gatsby'

const Sticky = () => {
  return (
            <nav className={style.sticky}>
                <ul className={style.navLinksSecondary}>

                    <a href="/products" className={style.navLinkText}>
                        <li className={style.navLinkItem}>
                        <Link to="/products" className={style.navLinkText}> 
                            Products
                        </Link>
                        </li>
                    </a>

                    <a href="/reviews" className={style.navLinkText}>

                        <li className={style.navLinkItem}>
                            <Link to="/reviews" className={style.navLinkText}> 
                                Reviews
                            </Link>
                        </li>
                    </a>

                    <a href="/guides" className={style.navLinkText}>
                        <li className={style.navLinkItem}>
                        <Link to="/guides" className={style.navLinkText}> 
                            Buying Guides
                        </Link>
                        </li>  
                    </a>        

                </ul>
            </nav> 
  )
}

export default Sticky