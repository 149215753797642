// Step 1: Import React
import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../components/layout2'
import Seo2 from '../components/seo2'
import Disclaimer from '../components/disclaimer'
import { 
  blogTitle,
  tileContainer,
  tile,
  tileImg,
blogBullets } from '../components/layout.module.css'

const ProductPage = ({ data }) => {
  return (
    <Layout2 pageTitle="Products">
          <div className={tileContainer}>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <div className={tile}>
              <a href="https://www.amazon.com/Starbucks-Breakfast-Medium-Single-Brewers/dp/B00U3ODTTM?crid=10FZFDPF8B5SF&keywords=starbucks+k+cups&qid=1667875514&sprefix=starbucks+k+cups%2Caps%2C94&sr=8-12&linkCode=li3&tag=thewanderi09d-20&linkId=6ddc7c4e7d23ceccef149d695e465740&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
              <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00U3ODTTM&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" alt=""/>
              </a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B00U3ODTTM" width="1" height="1" border="0" alt=""/>
            </div>

            <a className={tile} href="https://www.amazon.com/Starbucks-Cinnamon-Flavored-Blonde-Brewers/dp/B00U3ODXTS?crid=10FZFDPF8B5SF&keywords=starbucks+k+cups&qid=1667875514&rdc=1&sprefix=starbucks+k+cups%2Caps%2C94&sr=8-10&linkCode=li3&tag=thewanderi09d-20&linkId=42cf2a98c687f18adef7d105b672f2b7&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
              <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00U3ODXTS&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" alt=""/>
            </a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B00U3ODXTS" width="1" height="1" border="0" alt=""/>
          
            <a className={tile} href="https://www.amazon.com/Starbucks-Italian-Single-Coffee-Brewers/dp/B01N9Q93Y5?crid=10FZFDPF8B5SF&keywords=starbucks+k+cups&qid=1667875514&sprefix=starbucks+k+cups%2Caps%2C94&sr=8-9&linkCode=li3&tag=thewanderi09d-20&linkId=662fce8fd6798acf2aafde318538a07f&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer">
              <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01N9Q93Y5&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" alt="" />
              </a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B01N9Q93Y5" width="1" height="1" border="0" alt=""/>
        
              <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

            <a className={tile} href="https://www.amazon.com/Logitech-Wireless-Lightspeed-Headset-Headphone/dp/B081PP4CB6?th=1&linkCode=li3&tag=thewanderi09d-20&linkId=5723b1e907a0650ba9e220fd64eb97d7&language=en_US&ref_=as_li_ss_il" target="_blank">
            <img className={tileImg} border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081PP4CB6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=thewanderi09d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=thewanderi09d-20&language=en_US&l=li3&o=1&a=B081PP4CB6" width="1" height="1" border="0" alt=""/>

        
        </div>

          
       {
      //  data.allMdx.nodes.map((node) => (
      //    <article key={node.id}>
      //      <h2>
      //       <Link className={blogTitle} to={`/reviews/${node.frontmatter.slug}`}>
      //          {node.frontmatter.title}
      //        </Link>
      //        </h2>
      //      <ul>
      //       <li className={blogBullets}>Posted: {node.frontmatter.date}</li>
      //      <li className={blogBullets}>Location: {node.frontmatter.location}</li>
      //    </ul>
      //    <p>{node.excerpt}</p>
      //
      //        </article>
      //    ))
      }
              <Disclaimer />
    </Layout2>
  )
}

// searches only for blogs in /blog/reviews directory
export const query = graphql`
    query {
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {internal: {contentFilePath: {regex: "/(reviews)/"}}}
      ) {
        nodes {
          frontmatter {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            location
          }
          id
          excerpt
          parent {
            ... on File {
              modifiedTime(formatString: "MMMM D, YYYY")
            }
          }
        }
      }
    }
    `

export const Head = () => <Seo2 title="Products" />

export default ProductPage