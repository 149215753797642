import * as React from "react"
import * as style from './layout.module.css'

const Disclaimer = () => {
  return (
    <div className={style.disclaimer}>
        <p>Disclaimer: This page may have affiliate links, which means I may receive commissions if you choose to purchase through links I provide (at no extra cost to you). As an Amazon Associate I earn from qualifying purchases. 
        </p>
    </div>
  )
}

export default Disclaimer
